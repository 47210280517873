body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff !important;
}
.light_theme .navbar-dark .navbar-nav .nav-link {
  color: black !important;
  font-weight: 500;
}
.btn.getstarted.small {
  min-width: 50px;
}

.nounder {
  text-decoration: none;
}

.input-group-text .dropdown-item:focus {
  background-color: var(--themeclr);
}
/* .newdetail{
  background: url("./Assets/images/grunimg/img1.png");
  background-repeat: no-repeat;
} */

#loader_div {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: #000000c7;
  z-index: 99999;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loader img {
  animation: loader;
  animation-iteration-count: infinite;
  animation-duration: 1s;
}

@keyframes loader {
  0% {
    transform: rotateY(360deg);
  }

  100% {
    transform: rotateY(0deg);
  }
}

.transform-component-module_wrapper__1_Fgj {
  height: unset !important;
  width: unset !important;
  overflow: auto;
}

.colors {
  padding: 15px;
  /* background: #6c666691; */
  background: #000000 0% 0% no-repeat padding-box;
  max-width: 450px;
  position: absolute;
  /* top: 14px; */
  /* top: 100px; */
    /* left: 50px; */
  /* bottom: 40px; */
  bottom: 90px;
  right: 40px;
  z-index: 9;
  border: 1px solid var(--themebtncolor);
  border-radius: 8px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.light_theme .colors {
  background: #4f862d 0% 0% no-repeat padding-box;
}
.colors p {
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.colors .d-flex {
  gap: 7px;
}

.colors p span {
  height: 10px;
  width: 10px;
  display: inline-block;
  border-radius: 2px;
  margin-left: 14px;
  margin-right: 2px;
}

.colors p span.avail {
  /* background-color: #f6df2d; */
  background-color: #dcc408;
}

.colors p span.wait {
  /* background-color: #c8ab06; */
  background-color: #BD9C19;
}

.colors p span.soon {
  /* background-color: #9b904f; */
  background-color: #4F862D;
}

.noshrink {
  flex-shrink: 0;
}

@media(max-width:575px) {
  .colors .d-flex {
    gap: 2px;
  }

  .colors {
    max-width: 372px;
    padding: 12px;
    left: 12px;


  }

  .colors p {
    font-size: 11px;
  }
}

@media(max-width:575px) {
  .colors {
    left: 5px;
    right: 5px;
  }
}

.latestarticle .token_items {
  min-height: 132px;
}

.latestarticle .token_items h2 {
  color: var(--themebtncolor);
  font-family: extrabold;
  padding: 8px 14px;
  margin-bottom: 0;
  margin-top: 14px;
  font-size: 22px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 388px;
  white-space: nowrap;
}

.latestarticle .owl-nav {
  position: unset !important;
  width: 100%;
  top: 0;
  right: 0 !important;
  bottom: 0 !important;
  margin: auto;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.latestarticle .owl-nav .owl-prev {
  position: absolute;
  left: -6%;
  z-index: 2;
  top: 40%;
  background: #55464600 !important;
  color: #fff !important;
}

.latestarticle .owl-nav .owl-next {
  position: absolute;
  right: -6%;
  z-index: 2;
  top: 40%;
  background: #55464600 !important;
  color: #fff !important;

}

.carouselcontainer {
  max-width: 90%;
}

.latestarticle .owl-nav .owl-prev span,
.latestarticle .owl-nav .owl-next span {
  font-size: 52px;
}

.latestarticle a.col_token {
  height: 100%;
  display: block;
}

.latestarticle .token_list {
  height: 100%;
}

.latestarticle .owl-item {
  z-index: 1;
}

.latestarticle .owl-stage {
  /* display: flex; */
}

.carouselcontainer .token_list {
  margin-right: 0 !important;
}

.mapimg {
  background-image: url("./Assets/images/world.svg");
  width: 100vw;
  height: 100vh;
  background-repeat: repeat;
}




/* hari.css */
.land {
  padding-top: 6%;
}

.titles {
  color: var(--themetextclr);
}

.subtitles {
  color: var(--themebtncolor);
}

.memberlist {
  background: #05080D 0% 0% no-repeat padding-box;
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  max-width: 70rem;
  width: 100%;
  margin: auto;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.light_theme .memberlist{
  background: #FAFAFA 0% 0% no-repeat padding-box;
}
.memberlist h1 {
  color: #E3B72C;
}
.light_theme .memberlist h1{
  color: black;
  font-weight: 600;
}
.light_theme .memberlist p{
  color: black;
}
.stepss {
  border: 1px solid #243d15;
  background-color: #4f862d;
  border-radius: 50%;
  /* padding: 10px; */
  display: flex;
  align-items: center;
  height: 50px;
  width: 65px;
  /* text-align: center; */
  justify-content: center;
  animation: glow 2s infinite alternate;
}
.light_theme .stepss{
  
}
.stepss2{
  width: 50px !important;
}
.stepss3{
  width: 170px !important;
}

@keyframes glow {
  from {
    box-shadow: 0 0 10px -10px #243d15;
  }

  to {
    box-shadow: 0 0 10px 10px #243d15;
  }
}

.maxing {
  width: 100%;
  max-width: 90%;
  margin: auto;
}
.accordion-item button{
  background-color: #4F862D;
  color: #fff;
}
.accordion-item {
  border: 1px solid rgb(79 134 45);
}
.accordion-button:not(.collapsed) {
  color: #fff !important;
  background-color: #4f862d !important;
  box-shadow: inset 0 -1px 0 rgba(0,0,0,.125);
}
.accordion-button::after {
  filter: invert(1);
}
.accordion-item {
  background-color: black !important;
}
.light_theme .accordion-item {
  background-color: transparent !important;
  color: black;
  font-weight: 500;
}
.newsletter {
  padding: 10px 30px 22px 30px !important;
  max-width: 100rem !important;
}
.letter{
  color: var(--themebtncolor) !important;
  text-align: left !important;
  font-size: 26px;
}
.subletter{
  text-align: left !important;
}
.newsletter .form-control{
  background: #FFFFFF1A 0% 0% no-repeat padding-box;
  border: 1px solid #1e1e1e;
  border-radius: 6px !important;
  color: #fff !important;
  font-size: 14px;
}
.light_theme .newsletter .form-control{
  color: black !important;
}
.form-control:focus {
  box-shadow: 0 0 0 0.25rem rgb(79 134 45 / 35%) !important;
}
.accordion-button:focus {
  box-shadow: 0 0 0 0.25rem rgb(79 134 45 / 35%) !important;
}
.newsletter input::placeholder{
  color: #fff !important;
  font-size: 14px;
}
.light_theme .newsletter input::placeholder{
  color: black !important;
}
.footer a{
  margin-left: 12px;
}
.footer img{
  max-width: 34px;
  /* border-radius: 50%; */
}
.footer img:hover{
  filter: drop-shadow(2px 4px 8px #00800078);
}
/* .maxing img{
  -webkit-animation: bounceRight 3s infinite;
  animation: bounceRight 3s infinite;
}@-webkit-keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  60% {
    -webkit-transform: translateX(-15px);
    transform: translateX(-15px);
  }
}
@-moz-keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(-30px);
  }
  60% {
    transform: translateX(-15px);
  }
}
@keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -ms-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  60% {
    -ms-transform: translateX(-15px);
    transform: translateX(-15px);
  }
} */
.react-read-more-read-less,.react-read-more-read-less-more{
  color: var(--themetextclr);
  font-weight: 500;
}
.themesub{
  color: var(--themebtncolor) !important;
}
.partner img{
  filter: drop-shadow(2px 4px 4px #ffffffb5);
}
.light_theme .partner img {
  filter: drop-shadow(2px 4px 4px #ffffffb5);
  background: #9e9e9e;
  border-radius: 18%;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.partner img:hover{
  filter: drop-shadow(2px 4px 4px #bd9c19a8);
}
.stepss p {
  font-size: 24px !important;
  font-weight: 600 !important;
}
.smallsub{
  width: 100%;
    max-width: 40rem;
    margin: auto;
}
.stepno {
  color: var(--themebtncolor) !important;
  font-size: 24px !important;
  font-weight: 500 !important;
}
.launchpads,.lockedstake{
  padding: 129px 0px 0px 0px;
}
.bgcolor{
    background-color: #5c9c34 !important;
}
@media only screen and (max-width: 1199px) {
  .maxing img {
    max-width: 40px;
  }
}

@media only screen and (max-width: 860px) {
  .maxing img {
    max-width: 30px;
  }

  .stepss {
    height: 42px;
    width: 100px !important;
  }
  .stepss3{
    width: 180px !important;
  }
  .stepss p {
    font-size: 20px !important;
  }
}

@media only screen and (max-width: 767px) {
  .maxing img {
    display: none;
  }

  .stepss {
    height: 45px;
    width: 45px;
  }
  .stepss3 {
    width: 106px !important;
}
  .maxing .d-flex {
    /* justify-content: center; */
    justify-content: left;
    margin-bottom: 20px;
  }
}

@media(max-width:767px) {

  .latestarticle .owl-nav .owl-prev,
  .latestarticle .owl-nav .owl-next {
    position: unset;
  }

  .latestarticle .owl-nav {
    justify-content: center;
  }
}


@media only screen and (max-width: 575px){

  .newsletter .d-flex{
      display: unset !important;
  }
  .whatdoes .d-flex{
    display: unset !important;
  }
  .whatdoes a.ms-3{
    margin-left: 0px !important;
  }
  .newsletter button{
    margin-top: 10px;
  }
  .newletterrow{
    text-align: center !important;
  }
  .footer .textclr{
    font-size: 12px;
  }
}

@media only screen and (max-width: 350px){
.stepss {
    height: 45px;
    width: 60px;
}
.stepss3 {
  width: 200px !important;
}
}

.modal_forn_new label
{
color:#000 !important;
margin-bottom: 10px !important;
}

.modal_forn_new input::placeholder
{
  font-size: 14px !important;
}
 .a_with_text_modal p
 {
  color:#000;
  margin-top: 20px;
 }
 .modal_redirect_a
 {
    color: var(--themetextclr) !important;
    font-weight: 500;
    cursor: pointer !important;
 }
 .modal_redirect_a:hover{
  color:#4F862D !important;
 }

 @media only screen and (max-width:991px){

.navbar-collapse .btn.getstarted {
  width: 100%;
  max-width: 80%;
}
.navbar-collapse .btn.getstarted1
{
  width: 100%;
  max-width: 80% !important;
  margin-top: 10px;
  margin-bottom: 10px;
}
}